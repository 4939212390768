import React from "react";
import Slider from "react-slick";
import Card4 from "../../TestimonialCard/Card4/index";
import { studentData } from "./data";

import "./styles.scss";

function StudentSlider() {
  const settings = {
    prevArrow: "",
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="csm-main-placements">
        <div className="placements-container">
          <div className="placement-slider placement-student-slider">
            <h3 className="role-header">Student testimonials </h3>
            <Slider {...settings}>
              {studentData.map((info, key) => (
                <Card4
                  key={key}
                  image={info.image_url}
                  name={info.name}
                  college={info.college}
                  placed={info.placed}
                  description={info.description}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StudentSlider;
