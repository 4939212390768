import { StaticImage } from "gatsby-plugin-image";
import React from "react";

function HelpDesk() {
  return (
    <div className="help-desk">
      <div className="help-head">
        <div className="right-line"></div>

        <div className="head-text">Help Desk</div>
        <div className="left-line"></div>
      </div>

      <div className="help-email">
        <div className="help-mail-icon">
          <StaticImage
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m232h3os.png"
            alt="Mail"
          />
        </div>
        <div className="help-mail">
          If you have any questions, mail them to us at{" "}
          <a href="mailto:hi@kraftshala.com"> hi@kraftshala.com</a>
        </div>
      </div>
    </div>
  );
}

export default HelpDesk;
