


export const trainersImages = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wvuam.png",
    alt: "Kraftshala Digital Marketing Trainer_Varun_Satia",
  },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1kh5m2elstzu.png",
    alt: "Kraftshala Digital Marketing Trainer_Eshu",
  },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21w9jt0.png",
    alt: "Kraftshala Digital Marketing Trainer_Nishtha",
  },
  // {
  //   image:
  //     ShivangSethiya,
  //   alt: "Kraftshala Digital Marketing Trainer_Shivang",
  // },
  // {
  //   image:Dinesh,
  //   alt: "Kraftshala Digital Marketing Trainer_Dinesh",
  // },
  // {
  //   image:
  //    ayush,
  //   alt: "Kraftshala Digital Marketing Trainer_Ayush_Prakash",
  // },
  // {
  //   image:
  //    ashwini,
  //   alt: "Kraftshala Human Skills Trainer_ Ashwini_Raman",
  // },

  // {
  //   image:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/Sumona-Shetty.png",
  //   alt: "Sumona Shetty Human Skills, Kraftshala",
  // },
  // {
  //   image:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/Sambit-Dash.png",
  //   alt: "Sambit Dash VP & Head-Marketing Mamaearth",
  // },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wby9q.png",
    alt: "Kraftshala Digital Marketing Trainer_Piyush_Dhanuka",
  },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21uogtt.png",
    alt: "Digital Marketing Client Solutions Manager Arjun_Sood ",
  },
  {
    image:
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wovxm.webp",
    alt: "Kraftshala Digital Marketing Trainer_Tejas",
  },
  // {
  //   image:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/Ajitesh-Pandey.png",
  //   alt: "Ajitesh Pandey SEO head @ Sportskeeda",
  // },
  {
    image:
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21vta47.webp",
    alt: "Kraftshala Digital Marketing Trainer_Ayushi_Mona",
  },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21w5vu6.png",
    alt: "Kraftshala Digital Marketing Trainer_Mani_Makkar",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wt4lt.webp",
    alt: "Kraftshala Digital Marketing Trainer_Varun_Sethi",
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wm3nf.webp",
    alt: "Kraftshala Digital Marketing Trainer_Surjit",
    addStyles: true
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21w3blx.webp",
    alt: "Kraftshala Digital Marketing Trainer_Komal",
    addStyles: true
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21udecj.webp",
    alt: "Kraftshala Digital Marketing Trainer_Apurv",
    addStyles: true
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wi0je.webp",
    alt: "Kraftshala Digital Marketing Trainer_Sadashiv",
    addStyles: true
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21wfrb7.webp",
    alt: "Kraftshala Digital Marketing Trainer_Prashakth",
    addStyles: true
  },
];
