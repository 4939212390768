import React from "react";
import Seo from "../../../components/Seo/seo";
import Layout from "../../../components/Layout/layout";
import HeroSection from "../../../components/MLPPartTime/HeroSection/heroSection";
import ThankYou from "../../../components/Forms/ThankYou";
import GoTo from "../../../components/MLPPartTime/GoTo/goTo";
import NavBar from "../../../components/NavBar/navbar";
import Overview from "../../../components/MLPPartTime/Overview/overview";
import Placements from "../../../components/MLPPartTime/Placements/placements";
import Fee from "../../../components/MLPPartTime/Fee/fee";
import Benefits from "../../../components/MLPPartTime/Benefits/benefits";
import AdmissionProcess from "../../../components/MLPPartTime/AdmissionProcess/admissionProcess";
import Trainers from "../../../components/MLPPartTime/Trainers/trainers";
import Curriculum from "../../../components/MLPPartTime/Curriculum/curriculum";
import Faq from "../../../components/MLPPartTime/FAQS/faq";
import StudentSlider from "../../../components/MLPPartTime/StudentSlider/studentSlider";

import {
  courseSnipptJSON,
  faqSnipptJSON,
  videoSnipptJSON,
  eventSnipptJSON,
} from "../../../components/Seo/csmSeoData";

import "./styles.scss";

const PartTimeThankYou = ({ location }) => {
  let params = new URLSearchParams(location?.search);

  const navBarContent = [
    {
      name: "Overview",
      id: "overview",
    },
    {
      name: "Placements",
      id: "placements",
    },
    // {
    //   name: "Fee",
    //   id: "fee",
    // },
    {
      name: "Benefits",
      id: "benefits",
    },
    {
      name: "Admissions Process",
      id: "admissions",
    },
    {
      name: "Experts",
      id: "experts",
    },
    {
      name: "Curriculum",
      id: "curriculum",
    },
    {
      name: "FAQS",
      id: "faqs",
    },
  ];

  return (
    <React.Fragment>
      <Seo
        title="Part-Time Facebook & Google Ads Course with Placement - Kraftshala"
        description="Career boost with pay-after-placement part-time Google & Facebook Ads course. Live projects, industry expert interaction. Placements from 4.5 LPA. Enroll Now!"
        url="https://www.kraftshala.com/marketing-launchpad/part-time-digital-marketing-course/"
        courseSnipptJSON={courseSnipptJSON}
        faqSnipptJSON={faqSnipptJSON}
        videoSnipptJSON={videoSnipptJSON}
        eventSnipptJSON={eventSnipptJSON}
      />

      <div className="main-csm-parttime-page">
        <Layout formLink="/marketing-launchpad/part-time-digital-marketing-course/">
          {/* <div className="hero-div">
            <HeroSection urlParams={location?.search} />
            <div className="main-csm-part-form">
              <ThankYou name={params.get("name")} slot={params.get("slot")} />
            </div>
          </div> */}
          <div className="hero-div">
            <HeroSection urlParams={location?.search} />
            <ThankYou
              name={location?.state?.formFields?.name}
              userEmail={location?.state?.formFields?.email}
              campaignId={location?.state?.campaignId}
              slot={location?.state?.formFields?.preferredSlot}
            />
          </div>
          <GoTo />
          <NavBar
            content={navBarContent}
            applyLink={`/marketing-launchpad/part-time-digital-marketing-course/${location?.search}`}
          />

          <div id="overview" className="section">
            <Overview />
          </div>
          <div className="csm-main-divider"></div>

          <div id="placements" className="section">
            <Placements />
          </div>

          <div id="fee" className="section">
            <Fee />
          </div>

          <div id="benefits" className="section">
            <Benefits />
          </div>

          <div id="admissions" className="section">
            <AdmissionProcess urlParams={location?.search} />
          </div>

          <div id="experts" className="section">
            <Trainers />
          </div>

          <div id="curriculum" className="section">
            <Curriculum />
            <StudentSlider />
          </div>

          <div id="faqs" className="section">
            <Faq />
          </div>
          <div
            id="apply now"
            className="section"
            urlParams={location?.search}
          ></div>
        </Layout>
      </div>
      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default PartTimeThankYou;
