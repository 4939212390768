import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import HelpDesk from "../HelpDesk/index.jsx";
import { CONVEX_API_URL } from "../../../../config/config";

import "./styles.scss";

// import { graphql, useStaticQuery } from "gatsby";

function ThankYou(props) {
  const {
    name,
    preferredSlot,
    email,
    phone,
  } = props.formfields ?? {};

  const completeFormURL = `https://docs.google.com/forms/d/e/1FAIpQLSfC4IAAS7bVKq51aQi3xYpOVOXDVQyOKvH0LxnukbEBh6P5pg/viewform?usp=pp_url&entry.1745329396=${email ?? ""}&entry.2003462069=${name ?? ""}&entry.1865907026=${phone ?? ""}`;
  //Destructuring

  // const data = useStaticQuery(graphql`
  //   query {
  //     allMdx(filter: { frontmatter: { type: { eq: "luma" } } }) {
  //       nodes {
  //         frontmatter {
  //           mlp_form
  //         }
  //       }
  //     }
  //   }
  // `);

  //Luma link with UTM details
  const luma_link = `/kraftshala-live/register/467897d6-182b-456c-b5bc-d3556d19aa6a/${
    props.search
  }`;

  const processLead = async () => {
    const res = await fetch(`${CONVEX_API_URL}/lead/update`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        campaign_id: props.campaignId,
        email: email,
        registered_for_class: true,
      }),
    });

    const data = await res.json();

    window.open(luma_link, "_blank");
  };

  return (
    <div className="thank-you-container">
      <div className="submit-strip">
        <span className="tick-div">
          <StaticImage
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m232ivdq.png"
            alt="Success Tick"
          />
        </span>

        <span>Your Application has been submitted!</span>
      </div>

      <div className="greeting">
        <div className="welcome-text">Welcome Aboard</div>
        <div className="user-name">{name}</div>

        <div className="completion-stat">
          <p className="highlight-txt">
            <span style={{fontSize: "1.25rem"}}>85%</span> of selected students.
          </p>
          <div className="sub-text">completed their application in one go.</div>
        </div>
        <div className="action-container">
          <a href={completeFormURL} className="complete-form-btn">
            Complete Your Application Now
          </a>
          <a href="/kraftshala-live/" className="later-url">
            I’ll do it later
          </a>
        </div>
        <hr className="divider"/>
        <div className="next-steps-container">
          <div className="shared-on">
            <p className="sub-text">
              Next steps have been shared on your email
            </p>
            <p className="highlight-text">
              {email}
            </p>
          </div>
          <div className="chat-with-us">
            <a
              href="https://wa.me/message/IJYZ6OX2WTQCE1"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2330kig.png"
                alt="Chat with us whatsapp"
                placeholder="none"
              />
            </a>
          </div>
        </div>
        <hr className="help-desk-divider"/>
        <HelpDesk />
      </div>
    </div>
  );
}

export default ThankYou;
