import React from "react";
import LazyLoad from "react-lazyload";

import "./styles.scss";

/*Card for Student Testemonial*/
function Card4({ image, name, college,placed, description }) {
  return (
    <div className="card-type2-section student-section">
      <div className="type2-head">
        <div className="type2-img-div">
          <LazyLoad once>
            <img
              src={image}
              alt={name + " " + college}
              title={name + " " + college}
            />
          </LazyLoad>
        </div>

        <div className="type2-info">
          <div className="name">{name}</div>
          <div className="institute">{college}</div>
          <div className="company">Placed @ {placed}</div>
        </div>
      </div>

      <div className="type2-description1">{description}</div>
    </div>
  );
}

export default Card4;
